import { render, staticRenderFns } from "./cookiesblocked.vue?vue&type=template&id=c55f859e&scoped=true"
import script from "./cookiesblocked.vue?vue&type=script&lang=ts&setup=true"
export * from "./cookiesblocked.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./cookiesblocked.vue?vue&type=style&index=0&id=c55f859e&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c55f859e",
  null
  
)

export default component.exports